import React from "react";
import { Link as GatsbyLink } from "gatsby"

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  
  // React threw an error as there was a getProps property included in "other" that should not get passed down
  // to a dom element. In order to fix it I am subtracting this property from "other" and creating a new element "miscProps"
  // that can get passed down to a dom element
  const { getProps, ...miscProps} = other

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/|#(?!\/)/.test(to) // check for either links starting with / or anchor ones with a #
  const file = /\.[0-9a-z]+$/i.test(to)


  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    if (file) {
      return (
        <a href={to} {...miscProps}>
          {children}
        </a>
      )
    }
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} target="_blank" rel="noreferrer" {...miscProps}>
      {children}
    </a>
  )
}

export default Link
