export const size = {
    maxWidth: '77rem',
}

export const color = {

        // plue colors
        plue_light: "#4A33FF",
        plue400: "#4A33FF",
        plue300: "#6B66FF",
        plue700: "#240099",
        plue200: "#99A0FF",
        plue100: "#CCCFFF",
        plue25: "#F5F5FF",

        white: "#FFFFFF",
        gray25: "#EFEFF5",
        gray100: "#E3E3E8",
        gray200: "#CACACE",
        gray300: "#B0B0B5",
        gray400: "#97979B",
        gray500: "#7D7D82",
        gray600: "#646468",
        gray700: "#4B4B4E",
        gray800: "#313135",
        gray900: "#18181B",
        black: "#000000",


        richBlack: "#010203",
        main_dark: "#121212",
        // main_light: "#fafafa",
        main_light: "#f9f9fb",
        //accent_light: "#eaeaea",
        accent_light: "#EFEFF5",

        red: "#de166b",
        blue: "#4645b9",
        orange: "#ef7800",

        cta_green: "#0cb78b",
        rec: "#ff2d3f",
        plue_rec: "#ff6966",
       }